"use client";

import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ItemsPerPage } from "@/const";

export default function PaginationButtons({
  link,
  itemCount,
}: {
  link: string;
  itemCount?: number;
}) {
  const searchParams = useSearchParams();
  const lastPage = itemCount ? Math.floor(itemCount / ItemsPerPage) + 1 : 1;
  const currentPage = searchParams.get("page") ? +searchParams.get("page")! : 1;

  const count =
    lastPage > Math.floor(currentPage / 10) * 10 + 10
      ? Math.floor(currentPage / 10) * 10 + 10
      : lastPage;
  return (
    <div className="w-full flex justify-center pt-10">
      <Pagination
        hidePrevButton
        hideNextButton
        siblingCount={1}
        count={count}
        page={currentPage}
        renderItem={(item) => (
          <Link href={link + item.page}>
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          </Link>
        )}
      />
    </div>
  );
}
