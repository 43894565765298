import Image from "next/image";
import Link from "next/link";
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Button,
} from "@mui/material";

import { Post, WPTermCategory } from "@/type";

const Categories = ({ categories }: { categories: WPTermCategory[] }) => {
  if (!categories?.length) return null;

  return (
    <>
      <div className="flex space-x-2 pt-1">
        {categories.slice(0, 2).map((category) => (
          <Button sx={{ minWidth: 0 }} key={category.id}>
            <Typography
              color="primary"
              sx={{
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {category.name}
            </Typography>
          </Button>
        ))}
      </div>
    </>
  );
};

const ItemCard = ({
  post,
  inViewPort,
}: {
  post: Post;
  inViewPort: boolean;
}) => {
  const title = decodeURIComponent(post.title.rendered);
  const imageSrc =
    post._embedded?.["wp:featuredmedia"]?.[0]?.source_url ?? "/no-image.png";
  return (
    <>
      <Card className="w-[180px] sm:w-[260px]">
        <Link key={post.id} href={`/posts/${post.id}`}>
          <Image
            src={imageSrc}
            priority={inViewPort}
            alt={post.title.rendered}
            unoptimized
            width={260}
            height={260}
          />
        </Link>
        <CardContent sx={{ margin: -1 }}>
          <CardActionArea>
            <Link key={post.id} href={`/posts/${post.id}`}>
              <Typography
                fontWeight={500}
                sx={{
                  fontSize: 18,
                }}
              >
                {title}
              </Typography>
            </Link>
          </CardActionArea>
          <Categories categories={post._embedded?.["wp:term"]?.[0]} />
        </CardContent>
      </Card>
    </>
  );
};

export default ItemCard;
