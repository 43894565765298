export const API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.API_URL
    : "https://admin.petandwish.com/wp-json";

export const DISTRIBUTE_TOKEN = process.env.DISTRIBUTE_TOKEN;
export const USERNAME = process.env.USERNAME;
export const PASSWORD = process.env.PASSWORD;

export const ItemsPerPage = 20;
export const cachePolicy: any =
  process.env.NODE_ENV === "production" ? {} : { cache: "no-store" };

export const HOST_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.petandwish.com"
    : "http://localhost:3001";

export const SITEMAP_PAGE_SIZE = process.env.SITEMAP_PAGE_SIZE ?? "5000";

export class HttpError extends Error {
  constructor(statusCode: number) {
    super(`{statusCode:${statusCode}}`);
  }
}

export const SITE_INFO = {
  site: {
    title: "펫앤위시",
    titleSuffix: " - 펫앤위시",
    description:
      "펫앤위시에서 쿠팡 인기 상품, 가격변동을 확인하세요. 인기 상품들의 트렌드를 알려드립니다. ",
    descriptionSuffix: " - 펫앤위시",
    host: HOST_URL,
    logo: "/logo.png",
    icon: "/icon.png",
  },
  opengraph: {
    title: "펫앤위시",
    titleSuffix: " - 펫앤위시",
    description:
      "펫앤위시에서 쿠팡 인기 상품, 가격변동을 확인하세요. 인기 상품들의 트렌드를 알려드립니다. ",
    image: "/logo_og.png",
    descriptionSuffix: " - 펫앤위시",
  },
};
