"use client";

import { Button, Typography } from "@mui/material";
import Link from "next/link";
import { useDraggable } from "react-use-draggable-scroll";
import { useRef } from "react";
import { brown } from "@mui/material/colors";

import { Post } from "@/type";
import ItemCardProduct from "./ItemCardProduct";

type ViewProps = {
  title: string;
  sortBy?: string;
  data: Post[];
  viewType?: "scroll" | "content";
  inViewPort?: boolean;
  isExpand?: boolean;
};

const View = ({
  title,
  sortBy,
  data,
  viewType,
  inViewPort = false,
  isExpand = false,
}: ViewProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const { events } = useDraggable(ref as any);

  return (
    <div className="pb-8">
      <div className="flex justify-between items-center pb-2">
        <Typography variant="h6" component="h2" className="flex items-center">
          {title}
        </Typography>
        {isExpand && (
          <Link href={`/board/${sortBy}`}>
            <Button variant="outlined" size="small" sx={{ fontWeight: 700 }}>
              더보기
            </Button>
          </Link>
        )}
      </div>

      {!data?.length && (
        <div className="flex w-full h-[80vh] justify-center items-center">
          아직 아무런 포스팅이 없어요 ⚠️
        </div>
      )}

      <div className={`${viewType === "content" && "flex justify-center"}`}>
        <ul
          className={
            viewType === "scroll"
              ? "flex space-x-4 overflow-x-auto gap-4"
              : "grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6"
          }
          ref={ref}
          {...events}
        >
          {!!data?.length &&
            data?.map((item, index) => (
              <li key={index}>
                {
                  <ItemCardProduct
                    key={index}
                    post={item as Post}
                    inViewPort={
                      viewType === "scroll" && index < 7 ? true : false
                    }
                  />
                }
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default View;
